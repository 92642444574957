import request from '@/utils/request'

export function get(id) {
  return request({
    url: 'api/goods/' + id,
    method: 'get'
  })
}

export function add(data) {
  return request({
    url: 'api/goods',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/goods/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/goods',
    method: 'put',
    data
  })
}

export function getSku(id) {
  return request({
    url: 'api/sku/' + id,
    method: 'get'
  })
}

export function addSku(data) {
  return request({
    url: "api/goods/sku",
    method: "post",
    data
  })
}

export function delSku(id) {
  return request({
    url: 'api/goods/sku/' + id,
    method: 'delete'
  })
}

export function editSku(data) {
  return request({
    url: "api/goods/sku",
    method: 'put',
    data
  })
}

export function getPropertiesByCategory(categoryId) {
  return request({
    url: 'api/propertyDefine',
    method: 'get',
    params: {
      categoryId: categoryId
    }
  })
}

export function getByErpCode(code) {
  return request({
    url: 'api/goodsByErpCode',
    method: 'get',
    params: {
      code: code
    }
  })
}

export function getProperty(spuId) {
  return request({
    url: 'api/goods/property/' + spuId,
    method: 'get'
  })
}

export function saveProperty(spuId, data) {
  return request({
    url: 'api/goods/property/' + spuId,
    method: 'post',
    data
  })
}

export function saveBom(skuId, data) {
  return request({
    url: 'api/goods/bom/' + encodeURIComponent(skuId),
    method: 'post',
    data,
  })
}

export function getBom(skuId) {
  return request({
    url: 'api/goods/bom/' + encodeURIComponent(skuId),
    method: 'get'
  })
}

export function getSkus(spuId) {
  return request({
    url: 'api/goods/sku/' + encodeURIComponent(spuId),
    method: 'get'
  })
}

export function saveSkus(spuId, data) {
  return request({
    url: 'api/goods/sku/' + encodeURIComponent(spuId),
    method: 'post',
    data
  })
}


export function getBasicSkus(spuId) {
  return request({
    url: "api/sku/bySpuId/" + spuId,
    method: "get"
  })
}

export function getSkuRetailPrice(shopId, skuId) {
  return request({
    url: "api/shop/areaPrice/sku",
    method: "get",
    params: {
      shopId,
      skuId
    }
  })
}

export function getMallSkus(spuId, sellerId) {
  return request({
    url: 'api/mall/goods/sku/' + encodeURIComponent(spuId),
    method: 'get',
    params: {
      sellerId: sellerId
    }
  })
}

export function getMallSpu(spuId, sellerId) {
  return request({
    url: 'api/mall/goodsById/' + encodeURIComponent(spuId),
    method: 'get',
    params: {
      sellerId: sellerId
    }
  })
}

export function getRetailMallSpuInfo(spuId) {
  return request({
    url: "api/shop/spu/" + spuId,
    method: "get"
  })
}

export function getRetailMallSkus(spuId) {
  return request({
    url: "api/shop/salesGoods",
    method: "get",
    params: {
      page: 0,
      size: 100000,
      spuId
    }
  })
}

export function getRetailMallSpuPrice(shopId, spuId) {
  return request({
    url: "api/shop/spu/price",
    method: "get",
    params: {
      shopId,
      spuId
    }
  })
}

export function updateOnShelf(id,data) {
  return request({
    url: 'api/goods/onshelf/'+id,
    method: 'put',
    data
  })
}

export function specRename(data) {
  return request({
    url: 'api/goods/sku/change/specs',
    method: 'put',
    data
  })
}